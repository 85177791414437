import * as React from "react";
import CCPANoticeContainer from "../containers/ccpa-notice";
import DefaultLayout from "../layouts/default";
export default () => {

  return (
    <>
      <DefaultLayout animatedHeader title="CCPA Notice">
        <CCPANoticeContainer />
      </DefaultLayout>
    </>
  );
};
